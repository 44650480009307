import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.scss']
})
export class ConfirmMessageComponent implements OnInit {

  constructor(public dialog: MatDialog,  public ConfirmMessage: MatDialogRef<ConfirmMessageComponent>, ) { }

  ngOnInit() {
  }

  confirmtion(confirm:boolean){
      this.ConfirmMessage.close(confirm)
  }
}
